<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col">
          <div class="create">
            <router-link class="w-full vave-btn btn-blue" :to="'/automations/create'">New Automation</router-link>
          </div>
        </div>
        <div class="col-auto">
          <router-link class="vave-btn mb-4" :to="{ name: 'automationsDocumentation' }">Documentation</router-link>
        </div>
      </div>

      <div class="table-container">
        <table class="table align-middle">
          <thead class="head">
            <tr>
              <th></th>
              <th>Description</th>
              <th>Succeeded in past 24 hours</th>
              <th>Last Execution</th>
              <th></th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="automation in automationsList.data" :key="automation.id" :class="{'inactive': automation.active == 'inactive'}">
              <td style="max-width: 60px;">
                <div class="d-flex flex-column text-center align-items-center">
                  <span v-if="automation.status == 'active'">
                    <svg class="status-icon green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                  </span>
                  <span v-else-if="automation.status == 'testing'">
                    <svg class="status-icon yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                    </svg>
                  </span>
                  <span v-else-if="automation.status == 'inactive'">
                    <svg class="status-icon gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        d="M9.9 232c4.1 0 7.6-3.1 8.1-7.2c5.6-42.9 22.5-82.3 47.6-115c2.5-3.3 2.3-7.9-.6-10.8c-3.3-3.3-8.8-3.1-11.7 .6C26.2 134.7 8 177.1 2.1 223.2c-.6 4.7 3.1 8.8 7.8 8.8zM109.8 65.7c32.7-25.2 72.1-42.1 115-47.6c4.1-.5 7.2-4 7.2-8.1c0-4.7-4.1-8.4-8.8-7.8C177.1 8 134.7 26.2 99.6 53.3c-3.7 2.9-4 8.4-.6 11.7c2.9 2.9 7.6 3.1 10.8 .6zM280 502.1c0 4.7 4.1 8.4 8.8 7.8c46.2-5.9 88.5-24.1 123.6-51.2c3.7-2.9 4-8.4 .6-11.7c-2.9-2.9-7.6-3.1-10.8-.6c-32.7 25.1-72.1 42.1-115 47.6c-4.1 .5-7.2 4-7.2 8.1zm166.3-99.9c-2.5 3.3-2.3 7.9 .6 10.8c3.3 3.3 8.8 3.1 11.7-.6c27.1-35.1 45.3-77.4 51.2-123.6c.6-4.7-3.1-8.8-7.8-8.8c-4.1 0-7.6 3.1-8.1 7.2c-5.6 42.9-22.5 82.3-47.6 115zM99 447c-3.3 3.3-3.1 8.8 .6 11.7c35.1 27.1 77.4 45.3 123.6 51.2c4.7 .6 8.8-3.1 8.8-7.8c0-4.1-3.1-7.6-7.2-8.1c-42.9-5.6-82.3-22.5-115-47.6c-3.3-2.5-7.9-2.3-10.8 .6zM53.3 412.4c2.9 3.7 8.4 4 11.7 .6c2.9-2.9 3.1-7.6 .6-10.8c-25.2-32.7-42.1-72.1-47.6-115c-.5-4.1-4-7.2-8.1-7.2c-4.7 0-8.4 4.1-7.8 8.8C8 334.9 26.2 377.3 53.3 412.4zM413 65c3.3-3.3 3.1-8.8-.6-11.7C377.3 26.2 334.9 8 288.8 2.1c-4.7-.6-8.8 3.1-8.8 7.8c0 4.1 3.1 7.6 7.2 8.1c42.9 5.6 82.3 22.5 115 47.6c3.3 2.5 7.9 2.3 10.8-.6zm33.3 44.8c25.2 32.7 42.1 72.1 47.6 115c.5 4.1 4 7.2 8.1 7.2c4.7 0 8.4-4.1 7.8-8.8c-5.9-46.2-24.1-88.5-51.2-123.6c-2.9-3.7-8.4-4-11.7-.6c-2.9 2.9-3.1 7.6-.6 10.8z"
                      />
                    </svg>
                  </span>
                  <span v-else-if="automation.status == 'failed' || automation.status == 'cancelled' || automation.status == 'on_hold' || automation.status == 'refunded'">
                    <svg class="status-icon red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                      />
                    </svg>
                  </span>

                  <span class="pale hidden small pt-1" style="width: 80px;">{{automation.status}}</span>
                </div>
              </td>
              <td class="dark-pale">{{automation.description}}</td>
              <td>
                <div class="d-flex flex-column">
                  <div class="pale pe-2">{{automation.succeeded_in_past_24_hours}}</div>
                  <div class="pale small hidden">Total Executions: {{ automation.total_count }}</div>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div class="pale">{{ formatValue(automation.last_succeeded_time, 'diffForHumans') }}</div>
                  <div class="pale small hidden">{{ formatValue(automation.last_succeeded_time, 'longDate') }}</div>
                </div>
              </td>
              <td>
                <div v-if="hasFailedInPastWeek(automation)" class="d-flex align-items-center">
                  <span style="color: #dd4f4fbf;">
                    <div class="d-flex flex-column">
                      <div>
                        <svg class="status-icon red pe-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                        </svg>
                        Recently failed
                      </div>
                      <div class="hidden small ps-4">{{ formatValue(automation.last_failed_time, 'longDate') }} ({{ formatValue(automation.last_failed_time, 'diffForHumans') }})</div>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <router-link class="vave-btn" :to="'/automations/' + automation.id">
                  <i class="fa-regular fa-pen-to-square me-1"></i>
                  Edit
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination class="paginator" :limit="4" align="center" :data="automationsList" @pagination-change-page="getAutomations"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";
import pagination from "laravel-vue-pagination";
import { formatValue } from "./../formatters";

export default {
  data() {
    return {
      automationsList: {},
    };
  },
  components: {
    pagination,
  },
  mounted() {
    this.retrieveAutomations();
  },
  watch: {
    $route() {
      this.getAutomations();
    },
  },
  methods: {
    formatValue,
    hasFailedInPastWeek(automation) {
      if (!automation.last_failed_time) {
        return false;
      }
      let lastFailed = new Date(automation.last_failed_time);
      let now = new Date();
      let diff = now.getTime() - lastFailed.getTime();
      let diffInDays = diff / (1000 * 3600 * 24);
      return diffInDays < 7;
    },
    retrieveAutomations() {
      if (!this.automationsList.data || this.automationsList.data.length <= 0) {
        this.getAutomations();
      }
    },
    getAutomations() {
      let page = this.$route.query.page ? this.$route.query.page : 1;
      this.automationsList = {};
      axios
        .get("/api/admin/automations?per_page=" + 12 + "&page=" + page)
        .then((response) => {
          if (response.data.data) {
            this.automationsList = response.data;
          }
        });
    },
    deleteAutomation(id) {
      if (
        !confirm(
          "do you want to delete the automation " +
            id +
            "? This action is irreversible"
        )
      ) {
        return;
      }
      axios.delete("/api/admin/automations/" + id).then(
        (r) => {
          this.getAutomations();
          if (r.status == 200) {
            this.$toast.success("Automation deleted");
            return;
          }
          this.$toast.error("Problem deleting the automation");
        },
        (e) => {
          this.$toast.error("Problem deleting the automation");
          console.log(e);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.create {
  padding-bottom: 20px;
}
.inactive {
  background: #eaeaea;
}
.table-container {
  .hidden {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
    opacity: 0;
  }

  // shadow
  tbody tr {
    .status {
      font-size: 0.9em;
    }
    &:hover {
      background: #fafafa;
      &.isToday {
        background: #fafafa;
      }

      .hidden {
        height: 22px;
        transition: 0.2s;
        opacity: 1;
      }
    }
    &.isToday {
      background: #f4fbfc;
      .pale {
        color: #999;
      }
    }
  }
}
</style>